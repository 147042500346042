
import * as moment from "moment";
declare var io: any; // declare the io variable


export interface User {
  _id: string;
  email: string;
  weight: number;
  weightUnit: string;
  dob: string;
  weightInKg?: number;
  ageInYears?: number;
  terraUserId: string;
  gender: string;
}

export interface Attendant {
  user: User;
  startTime: number;
  heartBeatStream: number[];
  lastMinute: number;
  heartBeat?: number;
  percentage?: number;
  calories?: number;
  lastEmit?: number;
  color?: string;
}


export function getTimer(minute: any, second: any): string {
  return `${minute < 10 ? `0${minute}` : minute}:${second < 10 ? `0${second}` : second}`;
}

export function calculateAverageHeartRate(heartRateValues: number[]): number {
  if (heartRateValues.length === 0) {
    return 0;
  }

  const sum = heartRateValues.reduce((acc, value) => acc + value, 0);
  const average = sum / heartRateValues.length;
  return average;
}

export function getCalories(attendent: Attendant, longTermData: Pick<Attendant, 'heartBeatStream' | 'startTime'>): number {
  const avgBpm = calculateAverageHeartRate(longTermData.heartBeatStream);
  const exerciseTime = (moment().unix() - longTermData.startTime) / 60;
  if (avgBpm === 0) {
    return 0;
  }

  const calories = burnedCalories(attendent.user, avgBpm, exerciseTime);
  console.log("calories:", calories, exerciseTime, avgBpm, moment().unix() - longTermData.startTime);
  return Math.floor(calories);
}

export function burnedCalories(user: User, avgBpm: number, exerciseTime: number): number {
  const genderFactor = user.gender === "female" ? 0.6309 : 0.4472;
  const weightFactor = user.gender === "female" ? 0.1988 : -0.1263;
  const ageFactor = user.gender === "female" ? 0.2017 : 0.074;
  const baseFactor = user.gender === "female" ? 55.0969 : 20.4022;

  let calories =
    (exerciseTime *
      (genderFactor * avgBpm +
        weightFactor * user.weightInKg +
        ageFactor * user.ageInYears -
        baseFactor)) /
    4.184;

  calories = calories < 0 ? 0 : calories;

  return calories;
}

export function getAgeInYears(dateOfBirth: string | number): number {
  if (!dateOfBirth || dateOfBirth === 0) {
    return 27;
  }
  const dob = moment(dateOfBirth);
  const currentTime = moment();
  const age = moment.duration(currentTime.diff(dob)).asYears().toFixed(2);
  return Number(age);
}

export function getWeightInKg(weight: number, weightUnit: string): number {
  if (!weight) {
    weight = 55;
    weightUnit = "KG";
  }
  let weightKg = 0;
  if (weightUnit === "KG") {
    weightKg = Number(weight);
  } else {
    weightKg = Number(weight) / 2.20462;
  }
  return Number(weightKg.toFixed(2));
}

export function isJsonString(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function transform(value: string): string {
  const words = value.split(" ");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(" ");
}

export function getCurrentDate(): number {
  return moment().startOf("day").utc().unix();
}
